import React, { useEffect } from 'react';
import MyProject from '../../MyProject/MyProject';
import project1 from '../../../assets/project1.png';
import project2 from '../../../assets/project2.png';
import project3 from '../../../assets/project3.png';
import project4 from '../../../assets/project4.png';
import project5 from '../../../assets/project5.png';
import project6 from '../../../assets/project6.png';
import project7 from '../../../assets/project7.png';
import './Mywork.scss';
import { selectIsFetching, selectProjects } from '../../../redux/projects/projectSelectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Spinner from '../../alone-spinner/Spinner';
import { fetchProjects } from '../../../redux/projects/projectActions';

const imgs = {
    "1": project1,
    "2": project2,
    "3": project3,
    "4": project4,
    "5": project5,
    "6": project6,
    "7": project7
};

const MyWork = ({projects, fetchProjects}) => {

    useEffect(() => {
        document.title = 'My Work | Software Engineer';
        fetchProjects();
    }, [fetchProjects]);

    return (
        <div className='mywork'>
            <h1 className='mywork__heading'>Portfolio</h1>
            {
                (<div className='mywork__projects'>
                    {
                        Boolean(projects) ? projects.sort((a, b) => a.order.localeCompare(b.order)).map(project => (
                            <MyProject img={imgs[project.order]} key={project._id} project={project}/>
                        )) : <Spinner />
                    }
                </div>)
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    isFetching: selectIsFetching,
    projects: selectProjects
})

const mapDispatchToProps = dispatch => ({
    fetchProjects: () => dispatch(fetchProjects())
})

export default connect(mapStateToProps, mapDispatchToProps)(MyWork);