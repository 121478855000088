import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import aboutmeimg from '../../../assets/aboutme.svg';
import {ReactComponent as Github} from '../../../assets/github.svg';

import './Aboutme.scss';

const Aboutme = () => {
    useEffect(() => {
        document.title = 'About Me | Software Engineer';
    }, []);

    return (
        <div className='aboutme'>
            <div className='heading-primary aboutme__heading'>
                <div className='heading-primary__tags'>
                    <span className='heading-primary__tag'>html</span>
                    <span className='heading-primary__tag' style={{marginTop: '-10px'}}>body</span>
                </div>
                <div className='aboutme__content'>
                    <div className='heading-primary__body aboutme__heading-body'>
                        <span className='heading-primary__tag'>h1</span>
                        <h1 className='aboutme__heading-primary'>About me <span className='heading-primary__tag aboutme__tag'>/ h1</span></h1>
                        <p className='aboutme__paragraph'>
                            🚀 Hi there! I'm a <strong>Software Engineer</strong> and <strong>UI/UX designer</strong> with a passion for crafting innovative solutions through code. Over the past 5 years, my journey has taken me through a diverse range of technologies, solidifying my expertise in .NET (C#) and JavaScript for front-end development.
                        </p>
                        <p className='aboutme__paragraph'>
                            🔧 My skill set includes a wide array of technologies: <strong>C#, .NET, C++, SQL Server, EF Core, NHibernate, Redis, Elasticsearch, Kafka, RabbitMQ, MassTransit, React, TypeScript, Sass, Angular, Node.js, Express, Java, Spring Boot, Git, Azure DevOps, CI/CD, Docker, Kubernetes, Microservices, and Distributed Systems.</strong>
                        </p>
                        <p className='aboutme__paragraph'> 👨‍💻 Currently, I'm working as a <strong>Software Engineer III</strong> at <strong>ProOffice Gruppen</strong>, contributing to the development and enhancement of e-learning solutions used by businesses globally. My responsibilities involve refining REST APIs, implementing microservices architecture, and optimizing CI/CD pipelines for efficient software deployment.</p>
                        <p className='aboutme__paragraph'> 📚 Currently, I'm diving into the realms of <strong>Data Science and Machine Learning</strong> in <strong>Python</strong>, gearing up for an exciting upcoming project.</p>
                        <p className='aboutme__paragraph'> 🌟 Let's connect and explore the endless possibilities at the intersection of technology and creativity!</p>
                        <div className='custom-heading__buttons aboutme__buttons'>
                            <Link className='custom-button custom-button-fill custom-heading__button aboutme__button aboutme__button-1' to='/mywork'>View Projects</Link>
                            <a href='https://github.com/filipboshevski?tab=repositories' target='_blank' rel="noopener noreferrer" className='custom-button custom-heading__button aboutme__button myproject__button-github aboutme__button-2' ><Github className='myproject__button-icon' />ALL PROJECTS</a>
                        </div>
                    </div>
                </div>
                <div className='heading-primary__closing-tags' style={{marginTop: '3rem'}}>
                        <span className='heading-primary__tag heading-primary__tag-close'>/ body</span>
                        <span className='heading-primary__tag heading-primary__tag-close'>/ html</span>
                </div>
            </div>
            <img src={aboutmeimg} alt='aboutme' className='aboutme__img' />
        </div>
    );
}

export default Aboutme;